<template>
	<div class="">
		<Card class="w-full">
			<div class="w-full">
				<div class="flex justify-between items-center mb-6 sm:flex-row flex-col">
					<h4 class="sm:mr-6 text-3xl font-semibold flex-start sm:w-max w-full gap-3.5">Vendors</h4>
					<CreateRfi />
				</div>
				<div class="flex justify-between mb-6 sm:flex-row flex-col gap-3.5">
					<select-dashboard class="md:w-72 flex-grow md:flex-grow-0 mr-6 sm:w-max w-full"
						label="Sort:"
						placeholder="All"
						@change="changeSort"
						:options="['No RFI: Newest', 'No RFI: Oldest', 'ITP 01 status: All', 'ITP 01 status: Updated','ITP 01 status: Not Uploaded', 'ITP 01 status: Expired']">
					</select-dashboard>
					<InputGista type=text
						size="large"
						placeholder="Search"
						v-model="search"
						class="md:w-96 flex-grow md:flex-grow-0">
						<template #icon-left>
							<span class="icon-search text-primary text-xl"></span>
						</template>
					</InputGista>
				</div>
				<div>
					<TableVendor :sort="sort" :itp01="itp01" :search="search" :isLoadingTable="isLoadingTable" @changeIsLoadingTable="changeIsLoadingTable" v-if="menuListVendors?.canRead" />
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
import SelectDashboard from "@/core/components/custom/SelectDashboard.vue";
import CreateRfi from "@/core/components/vendors/CreateRfi.vue";
import Card from '@/core/components/custom/Card.vue'
import TableVendor from '@/modules/module-dashboard/pages/TableVendor.vue'

export default {
	name: "Vendors",
	data() {
		return {
			isOpen: false,
			search: '',
			sort: '',
			itp01: '',
			timeout: null,
			isLoadingTable: true,
		}
	},
	computed: {
		menuListVendors() {
			return this.$store.getters['dashboard/menuListVendors']
		}
	},
	watch: {
		search() {
			console.log(this.search);
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(async () => {
				this.isLoadingTable = true
				await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, sort: this.sort, anySearch: this.search, itp01: this.itp01 })
				this.isLoadingTable = false
			}, 500)
		}
	},
	methods: {
		async changeSort(e) {
			console.log(e);
			if (e === 'No RFI: Newest') {
				this.sort = 'noRfi,desc'
				this.itp01 = ''
				this.isLoadingTable = true
				await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, sort: 'noRfi,desc', anySearch: this.search })
				this.isLoadingTable = false
			} else if (e === 'No RFI: Oldest') {
				this.sort = 'noRfi,asc'
				this.itp01 = ''
				this.isLoadingTable = true
				await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, sort: 'noRfi,asc', anySearch: this.search })
				this.isLoadingTable = false
			}	else if (e === 'ITP 01 status: All') {
				this.sort = ''
				this.itp01 = ''
				this.isLoadingTable = true
				await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, anySearch: this.search })
				this.isLoadingTable = false
			} else if (e === 'ITP 01 status: Updated') {
				this.itp01 = 'UPDATED'
				this.sort = ''
				this.isLoadingTable = true
				await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, anySearch: this.search, itp01: 'UPDATED' })
				this.isLoadingTable = false
			} else if (e === 'ITP 01 status: Not Uploaded') {
				this.itp01 = 'NOT_UPLOADED'
				this.sort = ''
				this.isLoadingTable = true
				await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, anySearch: this.search, itp01: 'NOT_UPLOADED' })
				this.isLoadingTable = false
			} else if (e === 'ITP 01 status: Expired') {
				this.itp01 = 'EXPIRED'
				this.sort = ''
				this.isLoadingTable = true
				await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, anySearch: this.search, itp01: 'EXPIRED' })
				this.isLoadingTable = false
			}
		},
		changeIsLoadingTable(e) {
			this.isLoadingTable = e
		}
	},

	async mounted() {
		this.sort = 'noRfi,desc'
		this.isLoadingTable = true
    await this.$store.dispatch('rfi/getVendorRfiList', { page: 0, size: 10, sort: this.sort })
		this.isLoadingTable = false

	},
	components: {
    SelectDashboard,
    CreateRfi,
    Card,
    TableVendor,
},
}
</script>