<template>
  <div>
    <div class="overflow-auto">
      <table class="w-full min-w-custom" aria-describedby="tableVendor">
        <thead>
          <tr class="border-b-2 border-primary-dark">
            <th scope="col" class="p-2.5">No</th>
            <th scope="col" class="p-2.5 text-left">No. Request</th>
            <th scope="col" class="p-2.5 text-left">Vendor Name</th>
            <th scope="col" class="p-2.5 text-left">ITP 01 Status</th>
            <th scope="col" class="p-2.5">Action</th>
    
          </tr>
        </thead>
        <tbody v-if="!isLoadingTable">
          <tr v-for="(n, i) in vendorRfiList?.vendorList" :key="i" class="border-b border-primary-light" :class="{'bg-primary-lightest': i % 2 === 1}">
            <td class="p-2.5 text-center">{{(currentPage-1)*pageCount+i+1}}</td>
            <td class="p-2.5">{{n.noRfi}}</td>
            <td class="p-2.5">{{n.vendorName}}</td>
            <td class="p-2.5">{{n.itp01Status}}</td>
            <td class="p-2.5 flex justify-center">
              <ButtonGista 
                @click="toggleView(n)"
                size="small"
                customClass="rounded-full">View</ButtonGista>
            </td>
          </tr>
        </tbody>
        <tbody v-else class="animate-pulse">
          <tr v-for="i in 10" :key="i" class="border-b border-primary-light" :class="{'bg-primary-lightest': i % 2 !== 1}">
            <td class="flex justify-center items-center px-2.5 h-12">
              <div class="w-6 h-4 rounded bg-gray-light"></div>
            </td>
            <td class="px-2.5 h-12">
              <div class="w-40 h-4 rounded bg-gray-light"></div>
            </td>
            <td class="px-2.5 h-12">
              <div class="w-20 h-4 rounded bg-gray-light"></div>
            </td>
            <td class="px-2.5 h-12">
              <div class="w-20 h-4 rounded bg-gray-light"></div>
            </td>
            <td class="flex justify-center items-center px-2.5 h-12">
              <div class="w-12 h-6 rounded-2xl bg-gray-light"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="w-full">
        <div v-if="vendorRfiList?.vendorList.length === 0"
          class="flex flex-col items-center justify-center h-80">
          <img src="@/core/assets/icons/message/noitems.svg"
            alt="error"
            class="w-20" />
          <div class="mt-3">
            <p class="text-gray">Tidak Ada Data</p>
          </div>
        </div>
      </div>
      <GistaModal 
        :value="modal.modalVendorDetail"
        width="2/3"
        scrollable
        @input="closeModal"
        customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
        <VendorDetail :vendor="vendor" @closeModal="closeModal"></VendorDetail>
      </GistaModal>
    </div>
    <div class="flex justify-center" v-if="vendorRfiList?.vendorList.length > 0">
      <PaginationGista
        :current="currentPage"
        :total="totalRecords"
        :per-page="pageCount"
        @page-changed="handlePageChange($event)"
        @paging-change="handlePagingChange($event)"
      >
      </PaginationGista>
    </div>
  </div>
</template>

<script>
import VendorDetail from '@/core/components/vendors/VendorDetail'

//Modal vendor detail harus di luar table, backdrop background black transparent tidak berfungsi di dalam table
export default {
  name: "TableVendor",
  props: ['sort', 'itp01', 'search', 'isLoadingTable'],
  data(){
    return {
      currentPage: null,
			totalRecords: null,
			pageCount: null,
      vendor: {},
    }
  },
  computed: {
    vendorRfiList() {
      return this.$store.state.rfi.vendorRfiList
    },
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		}
  },
  watch: {
		vendorRfiList: {
			handler() {
        this.setLocalState()
			},
			deep: true
		},
	},
  methods: {
    async handlePagingChange(e) {
      console.log(e, 'pagingChange');
      this.$emit('changeIsLoadingTable', true)
      await this.$store.dispatch('rfi/getVendorRfiList', { page: 1, size: e , sort: this.sort, anySearch: this.search, itp01: this.itp01 })
      this.$emit('changeIsLoadingTable', false)
    },
    async handlePageChange(e) {
      console.log(e, 'pageChanged');
      this.$emit('changeIsLoadingTable', true)
      await this.$store.dispatch('rfi/getVendorRfiList', { page: e, size: this.pageCount, sort: this.sort, anySearch: this.search, itp01: this.itp01 })
      this.$emit('changeIsLoadingTable', false)
    },
    toggleView(vendor) {
      console.log(this.modal.modalVendorDetail);
      this.vendor = vendor
			this.modal.modalVendorDetail = !this.modal.modalVendorDetail
    },
    closeModal() {
      this.modal.modalVendorDetail = !this.modal.modalVendorDetail
      this.handlePageChange()
    },
    setLocalState() {
      this.currentPage = this.vendorRfiList.pageNumber + 1
      this.totalRecords = this.vendorRfiList.totalElements
      this.pageCount = this.vendorRfiList.pageSize
    }
  },
  components: {
    VendorDetail
},
  mounted() {
    this.setLocalState()
  }
}
</script>

<style scoped>
.min-w-custom {
  min-width: 70rem;
}
</style>